import React, { useState, useCallback } from 'react';
import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import md5 from 'md5';
import TextField from '../../componentes/TextField';
import Button from '../../componentes/Button';
import Footer from '../../componentes/Footer';
import FormProvider from '../../FormProvider';
import { setUser, STORAGE } from '../../ducks/user';
import endpoints from '../../configuraciones/endpoints';
import axios, { setTokenHeader } from '../../configuraciones/axios';
import styles from './styles';
import Typography from '../../componentes/Typography';

const Login = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const [usuario, setUsuario] = useState({ Usuario: '', Contrasena: '', mantenerSesion: false });
  const [formErrors, setFormErrors] = useState({});

  /** Funcion que se ejecuta cuando hacen click en el checkbox de recordar crendenciales */
  const handleCheckboxChecked = useCallback((e) => {
    setUsuario((current) => ({ ...current, mantenerSesion: e.target.checked }));
  }, []);

  /** Funcion para iniciar sesion */
  const iniciarSesion = (e) => {
    const { Usuario, Contrasena } = usuario;

    let usuarioLogin = {
      Usuario,
      Contrasena: md5(Contrasena),
    };

    axios.post(endpoints.iniciarSesion(), usuarioLogin).then((sesion) => {
      if (usuario.mantenerSesion)
        localStorage.setItem(STORAGE, JSON.stringify(sesion));
      else
        sessionStorage.setItem(STORAGE, JSON.stringify(sesion));

      setTokenHeader(sesion.token);
      dispatch(setUser(sesion));
    });
  }

  return (
    <Grid container className={classes.main}>
      <Grid item xs={12}>
        <Grid container className={classes.body}>
          <Grid item md={8}>
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100%' pr={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Box display='flex' alignItems='flex-end' justifyContent='flex-end' flexDirection='column'>
                    <Box className={classes.logoCheck}>  
                      <img src='/assets/logoCheckAmarillo.svg' alt='logoAmarillo' />
                      <Typography className={classes.check} bold>CHECK</Typography>
                      <Typography className={classes.assistance}>Assistance</Typography>
                    </Box>
                    <Box display='flex'>
                      <Typography className={classes.textoAmarillo}>My</Typography>
                      <Typography className={classes.textoAmarillo} bold>ADMON</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormProvider onSubmit={iniciarSesion} onChangeErrors={setFormErrors}>
              <Grid container spacing={1} className={classes.card} id="card">
                <Grid item xs={12}>
                  <h2>INICIO DE SESIÓN</h2>
                  <p>Ingrese sus credenciales para identificarse</p>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent='center'>
                    <img src='/assets/logoGaman.png' alt='icono' className={classes.logo} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='Usuario'
                    placeHolder="Usuario"
                    value={usuario.Usuario}
                    onChange={setUsuario}
                    isHandleChange
                    required
                    error={formErrors.nombreCorto}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='Contrasena'
                    placeHolder="Contraseña"
                    type="password"
                    value={usuario.Contrasena}
                    onChange={setUsuario}
                    isHandleChange
                    required
                    error={formErrors.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel control={
                    <Checkbox
                      name='MantenerSesion'
                      value={usuario.mantenerSesion}
                      onChange={handleCheckboxChecked} />
                  }
                    label="Recordar mis credenciales" />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    name="iniciar"
                    label="Iniciar"
                    classesCustom={{ boton: classes.botonInicio }}
                    isSubmit
                  />
                </Grid>
              </Grid>
            </FormProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid >
  );
};

export default Login;
