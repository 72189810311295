import { useMemo } from 'react';
import propTypes from 'prop-types';

import {
  makeStyles, Box, Grid,
  Button,
} from '@material-ui/core';
import { useSelector } from 'react-redux'

import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flexStart',
    width: '100%',
  },
  mensaje: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    border: '2px solid #FFF',
  },
  btn: {
    width: '100%',
    maxHeight: '200px !important',
    height: 'auto !important',
    padding: '10px !important',
    borderRadius: 14,
    backgroundColor: '#EAF0F6',
    '&:nth-child(2n)': {
      backgroundColor: '#FFFFFF',
    },
  },
  textBlue: {
    color: theme.palette.azul,
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flexStart',
  }
}));

const Burbuja = ({ cliente, onClick }) => {
  const styles = useStyles();

  const notificaciones = useSelector(({ user}) => user.notificaciones);

  const tieneNotificacion = useMemo(() =>
    notificaciones.find((clienteNotificacion) => cliente._id === clienteNotificacion),
    [notificaciones, cliente],
  );

  return (
    <Button variant="text"
      className={styles.btn}
      onClick={() => onClick(cliente)}
    >
      <Box className={styles.root}>
        <Grid container spacing={0} style={{ textAlign: 'left' }}>
          <Grid item xs={12} >
            <Grid container>
              <Grid item xs={11} className={styles.flexStart}>
                <Typography>{cliente._id}</Typography>
              </Grid>
              <Grid item xs={1}>
                <div
                  className={styles.mensaje}
                  style={{
                    backgroundColor: tieneNotificacion ? '#0AC947' : '#EAF0F6',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>{`${cliente.Nombres} ${cliente.Apellidos}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={styles.textBlue}>{cliente.Empresa}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Button>
  )
};

Burbuja.propTypes = {
  /** Datos del cliente */
  cliente: propTypes.object.isRequired,
  /** Función cuando da clic en la burbuja */
  onClick: propTypes.func.isRequired,
};

export default Burbuja;
