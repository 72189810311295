import { IconButton } from "@material-ui/core"
import { IoMdClose } from 'react-icons/io'

const BotonCerrar = ({ onClick }) => {
  return <IconButton onClick={onClick}>
    <IoMdClose />
  </IconButton>
}

export default BotonCerrar;
