/* eslint-disable import/no-anonymous-default-export */

const SET_TOKEN = 'SET_TOKEN';
const SET_USER = 'SET_USER';
const REMOVE_TOKEN = 'REMOVE_TOKEN';
const SET_SOLO_TOKEN = 'SET_SOLO_TOKEN';
const SET_NOTIFICACIONES = 'SET_NOTIFICACIONES';
const SET_CHAT_VISIBLE = 'SET_CHAT_VISIBLE';
export const STORAGE = 'provetta-admon';

const user = JSON.parse(localStorage.getItem(STORAGE) || sessionStorage.getItem(STORAGE) || '{}');

const initalState = {
  isAuth: Boolean(user.token),
  privilegios: [],
  token: user.token || '',
  user: user.usuario || {},
  notificaciones: [],
  chatVisible: null,
};

export default (state = { ...initalState }, { type, payload }) => {
  switch (type) {
    case SET_TOKEN:
      return { ...state, token: payload, isAuth: true, };
    case SET_SOLO_TOKEN:
      return { ...state, token: payload };
    case SET_USER:
      return { ...state, user: payload, isAuth: true, };
    case REMOVE_TOKEN:
      return { ...state, token: '', user: {}, isAuth: false };
    case SET_NOTIFICACIONES:
      return { ...state, notificaciones: payload };
    case SET_CHAT_VISIBLE:
      return { ...state, chatVisible: payload }
    default:
      return state;
  }
};

export const setToken = (payload) => (dispatch) => {
  localStorage.setItem(STORAGE, payload);
  dispatch({ type: SET_TOKEN, payload, });
};

export const setUser = (payload) => ({ type: SET_USER, payload });

export const removeToken = () => {
  localStorage.removeItem(STORAGE);
  sessionStorage.removeItem(STORAGE);
  return {
    type: REMOVE_TOKEN,
  }
}

export const setSoloToken = (payload) => (dispatch) => {
  dispatch({ type: SET_SOLO_TOKEN, payload, });
};

export const cargarNotificacionesPendientes = (payload) => (dispatch) => {
  dispatch({ type: SET_NOTIFICACIONES, payload });
}

export const agregarNotificacion = (payload) => (dispatch, getState) => {
  const { user: { notificaciones, chatVisible  } } = getState();

  if (chatVisible === payload) return;

  const nuevasNotificaciones = [...notificaciones];
  if (!nuevasNotificaciones.includes(payload))
    nuevasNotificaciones.push(payload);
  dispatch({ type: SET_NOTIFICACIONES, payload: nuevasNotificaciones });
};


export const removerNotificacion = (payload) => (dispatch, getState) => {
  const { user: { notificaciones } } = getState();

  dispatch({
    type: SET_NOTIFICACIONES,
    payload: notificaciones.filter((i) => i !== payload),
  });
};

export const setChatVisible = (payload) => (dispatch) => {
  dispatch({
    type: SET_CHAT_VISIBLE,
    payload,
  })
}
