import { memo } from 'react';
import propTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import Typography from '../Typography';
import PaperContainer from '../PaperContainer';

const useStyles = makeStyles(() => ({
  label: {
    color: '#91929E',
  },
}));

const PaperListado = ({ elementos, datos, idTamanoPadre }) => {
  const styles = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PaperContainer idTamanoPadre={idTamanoPadre}>
          <Grid container spacing={4}>
            {elementos.map((elemento, i) => (
              <Grid item xs={12} key={i}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={styles.label}>{elemento.label}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {!!elemento.render ? elemento.render(datos[elemento.key]) : <Typography>{datos[elemento.key]}</Typography>}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </PaperContainer>
      </Grid>
    </Grid>
  )
};

PaperListado.propTypes = {
  elementos: propTypes.arrayOf(propTypes.shape({
    /** Texto a mostrar */
    label: propTypes.string.isRequired,
    /** Propiedad del objeto donde se encuentra el valor a mostrar */
    key: propTypes.string.isRequired,
    /** Nodo personalizado para renderizar */
    render: propTypes.func
  })),
  /** Objeto que contiene la información a mostrar */
  datos: propTypes.object,
  /** identificador de un elemento para calcular el tamaño */
  idTamanoPadre: propTypes.string,
};

export default memo(PaperListado);
