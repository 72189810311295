export const ERROR = 'Ocurrió un error.';
export const SUCCESS = 'Acción realizada con éxito.';

export const USUARIO_NO_PERMISO = 'No cuenta con los permisos necesarios.'
export const PERFILE_SIN_PERMISOS = 'Es necesario seleccionar al menos un módulo.';
export const POLIGONO_FUERA_SITIO = 'El polígono dibujado está fuera del sitio.';
export const POLIGONO_DENTRO_INVERNDERO = 'El polígono dibujado está dentro de otro invernadero.';
export const POLIGONO_VACIO = 'Es necesario capturar un polígono.';
export const NOMBRE_SECCION_REPETIDO = 'El nombre de la sección ya existe.';
export const NOMBRE_PLANTA_REPETIDO = 'El nombre de la planta ya existe.';
export const NOMBRE_SURCO_REPETIDO = 'El nombre del surco ya existe.';
export const NOMBRE_TRAMPA_REPETIDO = 'Existe una o mas trampas con el mismo nombre.';
export const NOMBRE_VALVULA_REPETIDO = 'El nombre de la válvula ya existe.';
export const TRAMPA_FUERA_POLIGONO = 'Todas las trampas deben estar dentro del polígono.';
export const ELEMENTO_NO_ENCONTRADO = 'El elemento no ha sido encontrado.';
export const INVERNADERO_NO_SELECCIONADO = 'Debe seleccionar un invernadero.';
export const NAVE_NO_SELECCIONADA = 'Debe seleccionar una nave.';
export const NAVE_SIN_GEOLOCALIZACION = 'La nave no cuenta con geolocalización.';
export const SITIO_NO_SELECCIONADO = 'Debe seleccionar un sitio.'
export const TIPO_SUELO_NO_SELECCIONADO = 'Debe seleccionar un tipo de suelo.'
export const GEOLOCALIZACION_NAVE_NO_CONFIGURADO = 'Debe configurar la geolocalización de la nave.';
export const SITIO_DEPENDENCIAS = 'No se puede actualizar las coordenadas porque cuenta con invernaderos asignados.';
export const INVERNADERO_DEPENDENCIAS = 'No se puede actualizar las coordenadas porque cuenta con naves asignadas.';
export const TEMPORADA_ACTIVA_REPETIDA = 'Solo puede existir una temporada activa.';
export const TEMPORADASITIO_REPETIDA = 'Las temporadas no pueden compartir el mismo periodo.';
export const NOMBRE_TEMPORADASITIO_REPETIDO = 'Los nombres de las temporadas deben ser únicos.';
export const FECHA_CULTIVO_FUERA_DE_RANGO = 'La fecha de cultivo no se encuentra dentro de la temporada.';
export const FECHA_CORTE_FUERA_DE_RANGO = 'La fecha de corte no se encuentra dentro de la temporada.';
export const FECHA_TRANSPLANTE_FUERA_DE_RANGO = 'La fecha de transplante no se encuentra dentro de la temporada.';
export const SITIO_SIN_TEMPORADAS = 'El sitio no cuenta con temporadas registradas.';
export const RANGO_INVALIDO = 'El rango de inicio no puede ser mayor al rango final y el rango final del estado verde debe ser menor a 100.';
export const VALIDAR_SELECCION_REPORTE = 'Debe seleccionar al menos un campo en cada sección';
export const NO_MODIFICAR_PERMISO_SITIO = 'No se puede modificar el permiso de un sitio si el usuario es administrador.';
export const CLIMA_ERROR = 'No fue posible obtener el clima';
export const CLIMA_HISTORIAL_ERROR = 'No fue posible obtener el historial del clima';

export const ERROR_COORDENADA_FUERA_CONTENEDOR = '¡La coordenada se encuentra fuera del poligono!';
export const ERROR_COORDENADA_EN_CONFLICTO_CON_TABLA = '¡La ubicación se encuentra en conflicto con otra tabla!';