import { useState, useEffect } from 'react';

import io from 'socket.io-client';

import general from '../configuraciones/general'

let socket;

const useSocket = (clienteId) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (!socket && clienteId) {
      socket = io(general.URL_SOCKET, {
        query: {
          clienteId,
        },
      });
    }

    if (socket) {
      setConnected(true);
    }
  }, [clienteId]);

  useEffect(() => {
    if (connected) {
      socket.emit('joinRoom', `cliente-${clienteId}`);
    }
  }, [connected, clienteId]);

  return socket;
};

export default useSocket;
