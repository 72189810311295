import { Box } from '@material-ui/core';

import Typography from '../Typography';

import styles from './styles';

const LogoAppBar = () => {
  const clases = styles();

  return (
    <Box className={clases.root}>
      <Typography className={clases.admon} bold>ADMON</Typography>
      <Box>
        <Typography className={clases.check}>Check</Typography>
        <Typography className={clases.assistance}>Assistance</Typography>
      </Box>
    </Box>
  )
}

export default LogoAppBar;
