import React from 'react';

import { AppBar } from '@material-ui/core';

import styles from './styles';

const Footer = () => {
  const classes = styles();

  return (
    <AppBar className={classes.appBar} />
  );
};

export default Footer;
