const EventosSocket = {
  PUSH_MENSAJE: 'push_mensaje',
  ENVIAR_MENSAJE: 'enviar_mensaje',
  RECIBIR_MENSAJE: 'recibir_mensaje',
  ENTRAR_SALA: 'unirse_sala',
  CLIENTES_SALA: 'clientes_en_sala',
  MENSAJE_VISTO: 'mensaje_visto',
  NOTIFICACIONES_PENDIENTES: 'notificaciones_pendientes',
  NUEVA_NOTIFICACION: 'nueva_notificacion',
}

export default EventosSocket;
