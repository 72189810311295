import propTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

import OnlineDot from './OnlineDot';

const useStyles = makeStyles(() => ({
  avatarContainer: {
    width: 55,
    height: 55,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '100%',
      borderRadius: '50%',
      height: 'auto',
    }
  },
  root: {
    width: 55,
    height: 55,
  },
  onlineDot: {
    position: 'relative',
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 10,
    top: -15,
  },
}))

const ChatAvatar = ({ avatar, online }) => {
  const styles = useStyles();

  return <div className={styles.root}>
    <div className={styles.avatarContainer}>
      <img
        alt="avatar"
        src={avatar || '/assets/avatar-default.svg'} />
    </div>
      <div className={styles.onlineDot}>
        <OnlineDot online={online} />
      </div>
  </div>
}

ChatAvatar.propTypes = {
  /** Direccion url del avatar del empleado */
  avatar: propTypes.string,
  /** Estatus del chat del cliente */
  online: propTypes.bool.isRequired,
}

export default ChatAvatar;
