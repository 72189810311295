import propTypes from 'prop-types';
import { makeStyles, Grid, Box } from '@material-ui/core';
import moment from 'moment';

import Typography from '../Typography';

import TiposMensaje from '../../constantes/tiposMensaje';
import general from '../../configuraciones/general'

const useStyles = makeStyles(() => ({
  root: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
    },
    padding: 3,
  },
  nombre: {
    color: '#0A1629',
    marginRight: 10,
    fontSize: 14,
  },
  hora: {
    color: '#7D8592'
  },
}));

const Mensaje = ({ mensaje }) => {
  const styles = useStyles();

  return (
    <Grid container>
      <Grid item xs={1}>
        <Box className={styles.root}>
          <img
            alt="avatar"
            src={mensaje.TipoMensaje === TiposMensaje.SALIDA ? '/assets/icon-check.svg' : '/assets/avatar-default.svg'}
          />
        </Box>
      </Grid>
      <Grid item xs={11}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={styles.nombre}>
              {mensaje.TipoMensaje === TiposMensaje.SALIDA ? general.NOMBRE_CHAT : (mensaje.Cliente?.NombreCompleto || mensaje.cliente.nombre)}
            </Typography>
            <Typography className={styles.hora}>{moment(mensaje.FechaCreacion || '').format('dddd DD MMMM YYYY hh:mm A')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {mensaje.Contenido}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Mensaje.propTypes = {
  /** Mensaje a mostrar */
  mensaje: propTypes.string.isRequired,
};

export default Mensaje;
