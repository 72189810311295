import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    gap: 8,
    alignItems: 'center',
  },
  texto: {
    color: theme.palette.logoVerde,
  },
  admon: {
    color: theme.palette.logoVerde,
    fontSize: 32
  },
  check: {
    color: theme.palette.logoVerde,
    fontSize: 26,
    fontWeight: 'bold'
  },
  assistance: {
    color: theme.palette.logoVerde,
    fontSize: 18
  }
}));

export default styles;
