import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: paleta.bar.user,
    borderRadius: 10,
    padding: theme.spacing(0.5, 1),
    color: paleta.sidebar.backgroundColor,
    userSelect: 'none',
    '&.disabled': {
      backgroundColor: paleta.activo.primary,
    },
  },
  punto: {
    width: 15,
    borderRadius: '50%',
    height: 15,
  },
  flatContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 8,
  },
}));

export default styles;
