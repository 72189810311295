import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up("md")]: {
      padding: 20,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.footerLogin,
    color: 'white',
    height: 60
  },
}));

export default styles;
