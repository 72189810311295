/* eslint-disable array-callback-return */
import React from 'react';
import propTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { usePagination } from '@material-ui/lab';
import {
  IoIosArrowBack, IoIosArrowForward,
} from 'react-icons/io';
import { AiOutlineEllipsis } from 'react-icons/ai';
import cslx from 'clsx';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Typography from '../Typography';
import general from '../../configuraciones/general'
import styles from './styles';

const Paginador = ({ count, onPaginaChange }) => {
  const classes = styles();
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const { items } = usePagination({
    count: Math.ceil(count / general.ELEMENTOS_POR_PAGINA),
    page: parseInt(query.pagina || 1, 10),
  });

  const manejadorPagina = (p) => {
    if (onPaginaChange) onPaginaChange(p);
    const filtros = Object.entries(query).filter(val => val[0] !== 'pagina');
    const search = filtros.reduce((acc, val) => {
      return acc + `&${val[0]}=${val[1]}`;
    }, '')
    history.push({ search: `?pagina=${p}${search}` });
  };

  return (
    <ul className={classes.root}>
      {items.map((item, index) => {
        switch (item.type) {
          case 'previous':
            return <Button
              key={index}
              size="small"
              className={classes.btn}
              disabled={item.disabled}
              onClick={(e) => {
                item.onClick(e);
                const query = queryString.parse(history.location.search);
                manejadorPagina(parseInt(query.pagina || 1, 10) - 1);
              }}>
              <IoIosArrowBack size={18} className={classes.icon} />
              <Typography className={classes.label}>Anterior</Typography>
            </Button>;
          case 'next':
            return <Button
              key={index}
              size="small"
              className={classes.btn}
              disabled={item.disabled}
              onClick={(e) => {
                item.onClick(e);
                const query = queryString.parse(history.location.search);
                manejadorPagina(parseInt(query.pagina || 1, 10) + 1);
              }}>
              <Typography className={classes.label}>Siguiente</Typography>
              <IoIosArrowForward size={18} className={classes.icon} />
            </Button>;
          case 'page':
            return <Button size="small"
              key={index}
              onClick={(e) => {
                item.onClick(e);
                if (item.page !== parseInt(query.pagina || 1, 10))
                  manejadorPagina(item.page);
              }}
              className={cslx(classes.btn, { active: item.selected })}
              disabled={item.disabled}>
              <Typography className={classes.label}>{item.page}</Typography>
            </Button>
          case 'start-ellipsis':
          case 'end-ellipsis':
            return <Button
              key={index}
              size="small"
              className={classes.btn}
              disabled={true}
              onClick={item.onClick}>
              <AiOutlineEllipsis size={18} />
            </Button>
          default:
            return '1';
        }
      })}
    </ul>
  );
};

Paginador.propTypes = {
  count: propTypes.number,
  onPaginaChange: propTypes.func,
};

Paginador.defaultProps = {
  count: 0,
  onPaginaChange: null,
};

export default Paginador;
