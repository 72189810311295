import { useMemo } from 'react';

import moment from 'moment';

import Listado from '../../contenedores/Listados';

import { PAGOS } from '../../configuraciones/endpoints';


const Pagos = ({ history, location }) => {
  const formatoFecha = 'DD MMM YYYY';
  const cabeceros = useMemo(() => [
    { label: 'FECHA', transform: ({ FechaCreacion }) => moment(FechaCreacion).format(formatoFecha), textTransform: 'capitalize' },
    { label: 'FOLIO', key: 'PagoStripeId' },
    { label: 'CLIENTE/SUSCRIPTOR', transform: ({ Cliente }) => `${Cliente?._id} - ${Cliente?.Nombres} ${Cliente?.Apellidos}` },
    {
      label: 'PLAN',
      transform: ({ Plan, PeriodoInicio, PeriodoFin }) => {
        const inicio = moment(PeriodoInicio);
        const fin = moment(PeriodoFin);
        const tipoPago = fin.diff(inicio, 'month') > 1 ? 'Anual' : 'Mensual';
        return `${Plan?.Nombre.toUpperCase()} ${tipoPago}`;
      }
    },
    {
      label: 'MONTO',
      transform: ({ Monto, Moneda }) => `$${(Monto / 100).toFixed(2)} ${Moneda}` ,
      textTransform: 'uppercase',
    },
    {
      label: 'PERIODO PAGADO',
      textTransform: 'capitalize',
      transform:
        ({ PeriodoInicio, PeriodoFin }) =>
          `${moment(PeriodoInicio).format(formatoFecha)} - ${moment(PeriodoFin).format(formatoFecha)}`,
    },
  ], []);

  return <Listado
    titulo="Pagos"
    placeHolder="FOLIO/ID/SUSCRIPTOR"
    history={history}
    location={location}
    cabeceros={cabeceros || []}
    apiKey={PAGOS}
    onEdit={false}
    onDelete={false}
    agregar={false}
    filtrosBusqueda={{
      plan: true,
    }}
  />;
};

export default Pagos;
