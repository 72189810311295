import React, { useState, useCallback, useEffect } from 'react';
import propTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { IoMdAddCircleOutline } from 'react-icons/io';

import Boton from '../Button';
import TextField from '../TextField';
import Typography from '../Typography';
import Select from '../Select';

import axios from '../../configuraciones/axios';
import styles from './styles';
import endpoints from '../../configuraciones/endpoints';

const CabeceroListado = ({
  titulo,
  subtitulo,
  placeHolder,
  buscar,
  agregar,
  esListado,
  guardar,
  cancelar,
  customStyles,
  filtrosCabeceros
}) => {
  const classes = styles();

  const [txtBusqueda, setTxtBusqueda] = useState('');
  const [txtBusquedaAnterior, setTxtBusquedaAnterior] = useState('');

  const [filtros, setFiltros] = useState({
    planes: [],
    estados: [],
  });

  const [filtrosSeleccionados, setFiltrosSeleccionados] = useState({});

  const manejadorKeyDown = (e) => {
    if (e.key === "Enter" && buscar)
      if (txtBusquedaAnterior !== txtBusqueda) {
        buscar(({ ...filtrosSeleccionados, txtBusqueda }));
        setTxtBusquedaAnterior(txtBusqueda);
      }
  };

  const manejadorFiltros = ({ target: { value }}, name) => {
    buscar({ ...filtrosSeleccionados, txtBusqueda, [name]: value });
    setFiltrosSeleccionados((prev) => ({ ...prev, [name]: value }));
  }

  const obtenerFiltros = useCallback(async () => {
    if (!filtrosCabeceros.plan && !filtrosCabeceros.estado) return;

    const promesas = [
      filtrosCabeceros.plan && axios.get(endpoints.obtenerPlanes()),
      filtrosCabeceros.estado && axios.get(endpoints.obtenerEstatusSuscripcion()),
    ];
    const [{ planes }, estados = []] = await Promise.all(promesas);
    setFiltros({
      planes: [{ _id: '', Nombre: 'Todos' }, ...planes],
      estados: [{ _id: '', Nombre: 'Todos' }, ...estados],
    });
  }, [filtrosCabeceros]);

  useEffect(() => {
    obtenerFiltros();
  }, [obtenerFiltros]);

  return (
    <div>
      <Box flex={1} className={classes.root}>
        <div className={classes.titleContainer}>
          <Typography
            component="h2"
            className={classes.title}
            style={customStyles}
          >
            {titulo}
          </Typography>
          <Typography className={classes.subtitle}>{subtitulo}</Typography>
        </div>
        {esListado && (
          <Box flex={1} className={classes.actionContainer}>
            {buscar && (
              <TextField
                name='txtBusqueda'
                placeHolder={placeHolder}
                className={classes.textField}
                inputClassName={classes.textFieldInput}
                onChange={({ target: { value } }) => setTxtBusqueda(value)}
                value={txtBusqueda}
                onKeyDown={manejadorKeyDown}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
              />
            )}
            {filtrosCabeceros.plan && (
              <Select
                name='plan'
                label='Plan'
                placeHolder='Todos'
                className={classes.textField}
                options={filtros.planes}
                inputClassName={classes.textFieldInput}
                onChange={manejadorFiltros}
                value={filtrosSeleccionados.plan}
                valueProp='_id'
                labelProp='Nombre'
              />
            )}
            {filtrosCabeceros.estado && (
              <Select
                name='estado'
                label='Estado'
                placeHolder='Todos'
                className={classes.textField}
                options={filtros.estados}
                onChange={manejadorFiltros}
                value={filtrosSeleccionados.estado}
                valueProp='_id'
                labelProp='Nombre'
              />
            )}
            {agregar && (
              <Box className={classes.btnContainer}>
                <Boton
                  icono={<IoMdAddCircleOutline size={18} />}
                  label="Agregar"
                  onClick={agregar}
                  classesCustom={{
                    boton: classes.btnAdd,
                  }}
                />
              </Box>
            )}
          </Box>
        )}
        {!esListado && (
          <Box flex={1} className={classes.actionContainer}>
            {buscar && (
              <TextField
                name="txtBusqueda"
                placeHolder={placeHolder}
                className={classes.textField}
                onChange={({ target: { value } }) => setTxtBusqueda(value)}
                value={txtBusqueda}
                onKeyDown={manejadorKeyDown}
              />
            )}
            {guardar && (
              <Boton
                onClick={guardar}
                label="Guardar"
                classesCustom={{ boton: classes.btn }}
              />
            )}
            {cancelar && (
              <Boton
                onClick={cancelar}
                label="Cancelar"
                classesCustom={{ boton: classes.btn }}
                border
              />
            )}
          </Box>
        )}
      </Box>
    </div>
  );
};

CabeceroListado.propTypes = {
  titulo: propTypes.string.isRequired,
  subtitulo: propTypes.string,
  placeHolder: propTypes.string,
  buscar: propTypes.func,
  agregar: propTypes.func,
  esListado: propTypes.bool,
  guardar: propTypes.func,
  cancelar: propTypes.func,
  customStyles: propTypes.object,
  customCabecero: propTypes.node,
  filtrosCabeceros: propTypes.objectOf(propTypes.shape({
    plan: propTypes.bool,
    estado: propTypes.bool,
  })),
};

CabeceroListado.defaultProps = {
  subtitulo: '',
  placeHolder: '',
  buscar: null,
  agregar: null,
  esListado: true,
  guardar: null,
  cancelar: null,
  customCabecero: null,
  filtrosCabeceros: {
    plan: false,
    estado: false,
  },
};

export default React.memo(CabeceroListado);
