import React from 'react';
import propTypes from 'prop-types';

import clsx from 'clsx';
import { Box } from '@material-ui/core';

import Typography from '../Typography';

import styles from './styles';

const Estatus = ({ activo, label, backgroundColor, variante }) => {
  const classes = styles();

  if (variante === 'flat') {
    return <Box className={classes.flatContainer}>
      <div className={classes.punto} style={{ backgroundColor }}></div>
      <Typography>{label}</Typography>
    </Box>
  }

  return <Typography
    className={clsx(classes.root, { disabled: !activo })}
    style={backgroundColor && { backgroundColor }}
  >
    {!label && (activo ? 'Habilitado' : 'Inhabilitado')}
    {label && label}
  </Typography>
};

Estatus.propTypes = {
  /** Flag que indica si mostrar el label habilitado/deshabilitado */
  activo: propTypes.bool,
  /** Label a mostrar */
  label: propTypes.string,
  /** Color de fondo a mostrar */
  backgroundColor: propTypes.string,
  /** Variante del componente */
  variante: propTypes.oneOf(['rounded', 'flat'])
};

Estatus.defaultProps = {
  variante: 'rounded'
}

export default React.memo(Estatus);
