import React, { useCallback, useMemo } from 'react';
import { BiRightArrowAlt } from 'react-icons/bi';
import moment from 'moment';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { CLIENTES } from '../../configuraciones/endpoints';


const Clientes = ({ history, location }) => {
  const formatoFecha = 'DD MMM YYYY';
  const cabeceros = useMemo(() => [
    { label: 'ID', key: 'id' },
    { label: 'Cliente/Suscriptor', transform: ({ Nombres, Apellidos }) => `${Nombres} ${Apellidos}` },
    { label: 'Plan', transform: ({ Plan }) => Plan?.Nombre },
    {
      label: 'Vigencia',
      transform:
        ({ PeriodoInicio, PeriodoFin }) =>
          `${moment(PeriodoInicio).format(formatoFecha)} - ${moment(PeriodoFin).format(formatoFecha)}`
    },
    {
      label: 'Estado',
      transform: ({ SuscripcionEstatus }) =>
        <Estatus
          label={SuscripcionEstatus?.Nombre}
          backgroundColor={SuscripcionEstatus?.Color}
        />,
    },
  ], []);

  const onClick = useCallback((e) => {
    const { pathname } = location;
    history.push(`${pathname}/${e.id}`);
  }, [history, location]);

  const acciones = useMemo(() => [
    { icon: <BiRightArrowAlt />, onClick },
  ], [onClick]);

  return <Listados
    titulo="Clientes"
    placeHolder="ID/SUSCRIPTOR"
    history={history}
    location={location}
    showActions
    customActions={acciones}
    cabeceros={cabeceros || []}
    apiKey={CLIENTES}
    onEdit={false}
    onDelete={false}
    agregar={false}
    filtrosBusqueda={{
      estado: true,
      plan: true,
    }}
  />;
};

export default Clientes;
