/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Grid } from '@material-ui/core';

import Chat from '../../componentes/Chat';
import ClienteSuscripcion from '../../componentes/ClienteSuscripcion';
import Default from '../../contenedores/Default';
import PaperListado from '../../componentes/PaperListado';
import Typography from '../../componentes/Typography';
import BotonCerrar from '../../componentes/BotonCerrar';
import SuscriptorDetallePagos from '../../componentes/SuscriptorDetallePagos';
import { TabsHeader } from '../../componentes/TabControl';

import useSocket from '../../hooks/useSocket';

import axios from '../../configuraciones/axios';
import endpoints, { CLIENTES } from '../../configuraciones/endpoints';
import general from '../../configuraciones/general';

const Cliente = ({ match, history }) => {
  const { params: { id } } = match;
  const socket = useSocket(general.NOMBRE_CHAT);

  const [cliente, setCliente] = useState({ _id: id });
  const [indexActivo, setIndexActivo] = useState(0);

  const tabs = useMemo(() => [
    { id: 0, label: 'Suscripción', children: <ClienteSuscripcion cliente={cliente} /> },
    { id: 1, label: 'Pagos', children: <SuscriptorDetallePagos clienteId={id} /> },
    { id: 2, label: 'Chat', children: <Chat idTamanoPadre="main" cliente={cliente} tipoMensaje="S" /> },
  ], [cliente]);

  const elementosListado = useMemo(() => ([
    { label: 'ID Cliente', key: '_id' },
    { label: 'Nombre suscriptor', key: 'Nombres' },
    {
      label: 'Empresa',
      key: 'Empresa',
      render: (empresa) => <Typography style={{ color: '#3F8CFF' }}>{empresa}</Typography>
    },
    { label: 'Teléfono de Contacto', key: 'Telefono' },
    { label: 'Email', key: 'Email' },
  ]), []);

  const consultarDatosIniciales = useCallback(async () => {
    const clienteResponse = await axios.get(endpoints.base.url(CLIENTES, id));
    setCliente(clienteResponse);
  }, [id]);

  const regresar = useCallback(() => {
    history.replace('/suscriptores/clientes');
  }, [history]);

  useEffect(() => {
    if (indexActivo === 3) {
      socket.emit('visto', `cliente-${cliente.id}`, { tipoMensaje: 'S' });
    }
  }, [indexActivo]);

  useEffect(() => {
    consultarDatosIniciales();
  }, [consultarDatosIniciales]);

  return <Default
    titulo={''}
    placeHolder={''}
    mostrarCabeceroFormulario
  >
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h4">Detalle de suscripción</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <PaperListado elementos={elementosListado} datos={cliente} idTamanoPadre="main" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={7} md={8} sm={12} xs={12}>
            <TabsHeader tabs={tabs} onChangeActive={setIndexActivo} />
          </Grid>
          <Grid item lg={1}>
            <BotonCerrar onClick={regresar} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Cliente);
