/* eslint-disable import/no-anonymous-default-export */
import React, { Suspense, useEffect, useState } from 'react';
import {
  HashRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import AppBar from '../componentes/AppBar';

import NotFound from '../paginas/404';
import Forbidden from '../paginas/401';
import Login from '../paginas/Login';
import Dashboard from '../paginas/Dashboard';
import Clientes from '../paginas/Clientes';
import Cliente from '../paginas/Cliente';
import ChatEmb from '../paginas/ChatEmb';
import Conversaciones from '../paginas/Conversaciones';
import Pagos from '../paginas/Pagos';

export default () => {
  const user = useSelector(store => store.user, (prev, next) => prev.user === next.user);

  const PrivateRoute = ({ component, ...rest }) => {
    const [tienePermiso, setTienePermiso] = useState(true);
    const { isAuth, permisos, permisosConsultados } = useSelector(
      ({
        user: { isAuth }, appbar: { permisos, permisosConsultados }
      }) => ({ isAuth, permisos, permisosConsultados }),
      (prev, next) => {
        return (
          prev.isAuth === next.isAuth &&
          prev.permisosConsultados === next.permisosConsultados &&
          isEqual(prev.permisos, next.permisos)
        )
      }
    );
    rest.location.state = { ...rest.state, ...rest.location.state };

    useEffect(() => {
      if (permisosConsultados && rest.location.state.moduloID) {
        setTienePermiso(Boolean(permisos.find((permiso) => permiso.moduloID === rest.location.state.moduloID)));
      }
    }, [rest.location, permisos, permisosConsultados]);

    return (
      <Route
        {...rest}
        render={(props) => (isAuth && tienePermiso ? (
          React.createElement(component, props)
        ) : (
          <Redirect to={{ pathname: !isAuth ? '/login' : '/forbidden', state: { from: props.location, }, }} />
        ))
        }
      />
    );
  };
  const PublicRoute = ({ component, ...rest }) => {
    rest.location.state = { ...rest.state, ...rest.location.state };
    const { isAuth } = useSelector(({ user: { isAuth } }) => ({ isAuth }));
    console.log('PublicRoute');
    return (
      <Route
        {...rest}
        render={(props) => (isAuth ? (
          <Redirect to={{ pathname: '/dashboard', }} />
        ) : (
          React.createElement(component, props)
        ))}
      />
    );
  };

  return (
    <HashRouter>
      {(user.isAuth) && <AppBar />}
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <PublicRoute path={['/', '/login']} component={Login} exact />
          <PrivateRoute path="/dashboard" component={Dashboard} exact />
          <PrivateRoute path="/suscriptores/clientes" component={Clientes} exact />
          <PrivateRoute path="/suscriptores/clientes/:id" component={Cliente} exact />
          <PrivateRoute path="/suscriptores/pagos" component={Pagos} exact />
          <PrivateRoute path="/chat/conversaciones" component={Conversaciones} exact />
          <Route path="/frame-chat/:id" component={ChatEmb} exact />
          <Route path="/forbidden" component={Forbidden} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </HashRouter>
  );
};
