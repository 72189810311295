import propTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';

import Typography from '../Typography';
import Estatus from '../Estatus';
import useStyles from './styles';
import suscripcionEstatus from '../../constantes/suscripcionEstatus';

const ClienteSuscripcion = ({ cliente }) => {
  const styles = useStyles();
  const formatoFecha = 'DD MMMM YYYY';
  const { Plan, PeriodoInicio, PeriodoFin, SuscripcionEstatus } = cliente;

  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <Typography className={clsx(styles.titulo, styles.texto)} bold>
          {Plan?.Nombre}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={clsx(styles.texto, styles.label)} bold>
                  VIGENCIA DEL PERIODO
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textTransform: 'capitalize' }}>
                {moment(PeriodoInicio).format(formatoFecha)}
                {' - '}
                {moment(PeriodoFin).format(formatoFecha)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={clsx(styles.texto, styles.label)} bold>
                  PERIODO
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {`${(PeriodoInicio && PeriodoFin) ? Math.round(moment(PeriodoFin).diff(PeriodoInicio, 'd', true)) : ''} días ${SuscripcionEstatus?.Clave === suscripcionEstatus.EN_PRUEBA ? 'gratis' : '' }`}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={clsx(styles.texto, styles.label)} bold>
                  ESTATUS
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Estatus label={SuscripcionEstatus?.Nombre} backgroundColor={SuscripcionEstatus?.Color} variante='flat' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ClienteSuscripcion.propTypes = {
  /** Objeto que contiene la informacion del cliente */
  cliente: propTypes.shape({
    /** array que contiene la informacion del plan */
    suscripcions: propTypes.array.isRequired,
  }).isRequired,
};

export default ClienteSuscripcion;
