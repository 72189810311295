import React from 'react';
import propTypes from 'prop-types';
import { Tooltip, IconButton, Select, MenuItem, Chip } from '@material-ui/core';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { GiInfo } from 'react-icons/gi';
import clsx from 'clsx';
import Typography from '../Typography';
import { regexValid } from '../../utilidades/regex';
import styles from './styles';

const CustomSelect = ({
  label,
  value,
  name,
  onChange,
  isHandleChange,
  required,
  error,
  tooltip,
  className,
  classNameInput,
  labelProp,
  options,
  valueProp,
  multiple,
  customLabel,
  style,
  disabled,
  onClick,
  IconComponent,
  displayTextStyles,
  placeHolder,
  placeHolderColor,
  onDelete,
  onClickMenuItem
}) => {
  const classes = styles();
  const customOnChange = (e) => {
    if (regexValid(e) && onChange)
      if (isHandleChange)
        onChange((current) => ({ ...current, [name]: e.target.value }));
      else onChange(e, name);
  };

  const customOnClickItem = (value) => {
    if (onClickMenuItem)
      onClickMenuItem(value);
  };

  return (
    <div
      className={clsx(classes.root, className, { [classes.error]: error })}
      style={style}
    >
      <Typography className={classes.label}>
        {label}
        {label !== '' && required && ' * '}
        {tooltip !== '' && (
          <Tooltip
            title={<Typography style={{ fontSize: 14 }}>{tooltip}</Typography>}
          >
            <IconButton size='small'>
              <GiInfo />
            </IconButton>
          </Tooltip>
        )}
      </Typography>
      <Select
        variant='outlined'
        disabled={disabled}
        className={classNameInput || classes.input}
        onChange={customOnChange}
        IconComponent={IconComponent}
        name={name}
        displayEmpty={placeHolder ? true : false}
        renderValue={() => {
          let option = null;
          let renderValue = null;
          if (Array.isArray(value)) {
            option = options.filter((option) =>
              value.includes(option[valueProp])
            );
            renderValue =
              option?.map((option) => option[labelProp]).join(', ') || '';
          } else {
            option = options.find((option) => option[valueProp] === value);
            renderValue = option ? option[labelProp] : '';
          }
          if (Array.isArray(value) && onDelete) {
            option = options.filter((option) =>
              value.includes(option[valueProp])
            );
            const total = option.length - 2;
            renderValue =
              option?.map((option, index) => {
                if (index < 2) {
                  return (
                    <Chip
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      key={option.id}
                      label={`${option[labelProp]}`}
                      className={classes.chips}
                      deleteIcon={
                        <AiOutlineCloseCircle className={classes.iconDelete} />
                      }
                      onDelete={() => {
                        onDelete(option.id, name);
                      }}
                    />
                  );
                }
                if (index === 2) {
                  return (
                    <Chip
                      key={option.id}
                      label={`${total} Mas..`}
                      className={classes.chipsMas}
                    />
                  );
                }
                return null;
              }) || '';
          }
          return (
            <Typography
              style={{
                color: placeHolderColor,
                paddingRight: '20px',
                ...displayTextStyles,
              }}
            >
              {option ? renderValue : placeHolder}
            </Typography>
          );
        }}
        value={value}
        multiple={multiple}
        required={required}
        onClick={onClick}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            className={classes.li}
            value={valueProp ? option[valueProp] : option}
            onClick={() => customOnClickItem(option[valueProp])}
          >
            {customLabel ? customLabel(option) : option[labelProp]}
          </MenuItem>
        ))}
        {options.length === 0 && (
          <MenuItem disabled>Sin elementos para mostrar</MenuItem>
        )}
      </Select>
    </div>
  );
};

CustomSelect.propTypes = {
  label: propTypes.string,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
    propTypes.bool,
    propTypes.array,
  ]),
  name: propTypes.string.isRequired,
  onChange: propTypes.func,
  isHandleChange: propTypes.bool,
  required: propTypes.bool,
  error: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  tooltip: propTypes.string,
  className: propTypes.string,
  labelProp: propTypes.string,
  options: propTypes.array.isRequired,
  valueProp: propTypes.string,
  multiple: propTypes.bool,
  onDelete: propTypes.func,
  customLabel: propTypes.func,
  IconComponent: propTypes.func,
  styleComponent: propTypes.string,
  style: propTypes.object,
  disabled: propTypes.bool,
  onClick: propTypes.func,
  placeHolder: propTypes.string,
  placeHolderColor: propTypes.string,
  displayTextStyles: propTypes.object,
  onClickMenuItem: propTypes.func,
};

CustomSelect.defaultProps = {
  label: '',
  value: '',
  isHandleChange: false,
  required: false,
  error: false,
  tooltip: '',
  className: '',
  labelProp: 'label',
  valueProp: 'id',
  multiple: false,
  customLabel: null,
  onDelete: null,
  style: {},
  disabled: false,
  placeHolderColor: 'black',
  displayTextStyles: {},
  onClickMenuItem: null,
};

export default CustomSelect;
