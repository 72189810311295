import { useMemo, useCallback, useState, useEffect } from 'react';

import moment from 'moment';
import propTypes from 'prop-types';

import Table from '../../componentes/Table';

import general from '../../configuraciones/general';
import axios from '../../configuraciones/axios';
import endpoints from '../../configuraciones/endpoints';

const formato = 'DD MMM YYYY';

const SuscriptorDetallePagos = ({ clienteId }) => {
  const cabeceros = useMemo(() => [
    {
      label: 'PERIODO DE PAGO',
      transform: ({ PeriodoInicio, PeriodoFin }) => {
        const inicio = moment(PeriodoInicio);
        const fin = moment(PeriodoFin);
        const tipoPago = fin.diff(inicio, 'months') > 1 ? 'ANUAL' : 'MENSUAL';
        return `${inicio.format(formato)} - ${fin.format(formato)} ${tipoPago}`;
      },
      textTransform: 'capitalize',
    },
    {
      label: 'FECHA',
      transform: ({ FechaCreacion }) => moment(FechaCreacion).format(formato),
      textTransform: 'capitalize',
    },
    {
      label: 'MONTO',
      transform: ({ Monto, Moneda }) => `$${(Monto / 100).toFixed(2)} ${Moneda}`,
      textTransform: 'uppercase',
    }
  ], []);

  const [paginado, setPaginado] = useState({
    docs: [],
    count: 0,
  });
  const [pagina, setPagina] = useState(1);

  const consultarPagos = useCallback(async () => {
    const { docs, totalDocs } = await axios.get(endpoints.obtenerPagos(clienteId), {
      params: {
        pagina: pagina,
        registrosPorPagina: general.ELEMENTOS_POR_PAGINA,
      },
    });
    setPaginado({
      docs, count: totalDocs
    })
  }, [pagina, clienteId]);

  useEffect(() => {
    consultarPagos();
  }, [consultarPagos]);

  return (
    <Table
      count={paginado.count}
      rows={paginado.docs}
      headers={cabeceros}
      onPaginaChange={setPagina}
      showActions={false}
    />
  );
}

SuscriptorDetallePagos.propTypes = {
  /** Id del cliente */
  clienteId: propTypes.string
}

export default SuscriptorDetallePagos;
