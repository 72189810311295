/* eslint-disable import/no-anonymous-default-export */
export const MENU_OPCIONES = [
  {
    label: "Inicio",
    path: "/dashboard",
    index: 0,
  },
  {
    label: 'Suscriptores',
    submenu: [
      { label: 'Clientes', sublabel: 'Suscriptores', link: '/suscriptores/clientes', },
      { label: 'Pagos', sublabel: 'Suscriptores', link: '/suscriptores/pagos', },
    ],
    path: '/dashboard',
    index: 1,
  },
  {
    label: "Chat",
    index: 2,
    submenu: [
      { label: 'Conversaciones', sublabel: 'Chat', link: '/chat/conversaciones', },
    ],
    path: '/dashboard',
  },
];

const SET_INDEX_ACTIVO = 'SET_INDEX_ACTIVO';
const SET_SIDEBAR_ISOPEN = 'SET_SIDEBAR_ISOPEN';
const SET_PERMISOS = 'SET_PERMISOS';
const QUITAR_PERMISOS = 'QUITAR_PERMISOS';
const OCULTAR_OPCIONES = 'OCULTAR_OPCIONES';

const tabInitIndex = Number(sessionStorage.getItem('tabIndex')) || 0;

const initialState = {
  indexActivo: tabInitIndex,
  sidebarIsOpen: false,
  permisos: [],
  permisosConsultados: false,
  ocultarOpciones: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INDEX_ACTIVO:
      return { ...state, indexActivo: payload };
    case SET_SIDEBAR_ISOPEN:
      return { ...state, sidebarIsOpen: !state.sidebarIsOpen };
    case SET_PERMISOS:
      return { ...state, permisos: payload, permisosConsultados: true };
    case QUITAR_PERMISOS:
      return { ...state, permisos: [], permisosConsultados: false };
    case OCULTAR_OPCIONES:
      return { ...state, ocultarOpciones: payload };
    default:
      return state;
  }
};

export const setOpcionActiva = (payload) => ({
  type: SET_INDEX_ACTIVO,
  payload,
});

export const setSidebarIsOpen = () => ({ type: SET_SIDEBAR_ISOPEN });

export const setPermisos = (payload) => ({ type: SET_PERMISOS, payload });

export const quitarPermisos = () => ({ type: QUITAR_PERMISOS });

export const ocultarOpciones = (payload) => ({ type: OCULTAR_OPCIONES, payload });
