import propTypes from 'prop-types';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';

import AutoSizer from 'react-virtualized-auto-sizer';
import {
  makeStyles, Paper, Grid,
  useMediaQuery, Box, CircularProgress
} from '@material-ui/core';
import { FixedSizeList as List } from 'react-window';
import { useDispatch } from 'react-redux';

import { Skeleton } from '@material-ui/lab';

import Header from './Header';
import Mensaje from './Mensaje';
import Input from './Input';
import Burbuja from './Burbuja';

import useSocket from '../../hooks/useSocket';
import useOnScreen from '../../hooks/useOnScreen';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';

import EventosSocket from '../../constantes/eventosSocket';
import endpoints from '../../configuraciones/endpoints';
import general from '../../configuraciones/general';
import axios from '../../configuraciones/axios';
import { setChatVisible } from '../../ducks/user';

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #E6EBF5',
    borderRadius: 24,
    minHeight: 400,
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  mensajesContainer: {
    padding: 15,
    overflowY: 'scroll',
  },
  skeletonContainer: {
    gap: 8,
    display: 'flex',
    alignItems: 'center',
  },
  skeletonText: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    width: '100%'
  },
  skeletonRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  }
}));

const Chat = ({ idTamanoPadre, cliente, porcentajeAltura, tipoMensaje }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const match = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  const [height, setHeight] = useState(0);
  const [cargandoPaginado, setCargandoPaginado] = useState(false);
  const [clienteOnline, setClienteOnline] = useState(false);

  const socket = useSocket(general.NOMBRE_CHAT);
  const ref = useRef(null);
  const esVisible = useOnScreen(ref);

  const mensajesPorPagina = useMemo(() => 30, []);

  const Row = ({ index, style }) => <>
    {index === 0 && cargandoPaginado && <Box display='flex' justifyContent='center' style={style}><CircularProgress /></Box>}
    <div style={style}>
      <Mensaje mensaje={data.rows[index]} />
    </div>
  </>;

  const consultarPaginadoMensajes = useCallback(async (pagina, registrosPorPagina) => {
    if (!cliente._id) {
      setCargandoPaginado(false);
      return { docs: [], hasNextPage: false }
    }

    const { docs: mensajes, ...response } = await axios.get(endpoints.chat(cliente._id), {
      params: {
        pagina,
        registrosPorPagina,
        noajax: true,
      },
    });

    const docs = mensajes.sort((a, b) => {
      if (a.FechaCreacion < b.FechaCreacion) return -1;
      if (a.FechaCreacion > b.FechaCreacion) return 1;
      return 0;
    });

    setCargandoPaginado(false);
    return { docs, ...response };
  }, [cliente]);

  const {
    cambioPagina,
    data,
    setData,
    primeraCarga,
    reiniciarScroll,
  } = useInfiniteScroll(consultarPaginadoMensajes, { registrosPorPagina: mensajesPorPagina, reverso: true });

  const handleChatScroll = useCallback(({ scrollDirection, scrollOffset }) => {
    if (scrollDirection === 'forward' || scrollOffset >= 10) return;
    cambioPagina(() => setCargandoPaginado(true));
  }, [cambioPagina]);

  useEffect(() => {
    if (data.nuevoMensaje || data.primeraCarga) {
      setTimeout(() => {
        listRef.current?.scrollToItem(data.count - 1);
      }, 1);
      return;
    }
    listRef.current?.scrollToItem(mensajesPorPagina + 1);
  }, [data, mensajesPorPagina]);

  useEffect(() => {
    setTimeout(() => {
      const valor = (document.getElementById(idTamanoPadre)?.clientHeight * porcentajeAltura) || 0;
      setHeight(valor);
    }, 100);
  }, [idTamanoPadre, porcentajeAltura]);

  useEffect(() => {
    if (esVisible) {
      socket.emit(EventosSocket.MENSAJE_VISTO, {
        clienteId: cliente?._id,
        tipoMensaje,
      });
      dispatch(setChatVisible(cliente._id));
    }
  }, [esVisible, cliente, socket, tipoMensaje, dispatch]);

  useEffect(() => {
    reiniciarScroll();
  }, [cliente, reiniciarScroll]);

  useEffect(() => {
    return () => {
      dispatch(setChatVisible(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (socket && cliente?._id) {
      socket.on(EventosSocket.ENTRAR_SALA, (clientes) => {
        const clienteActual = clientes.find((clienteId) => clienteId === cliente._id);
        if (clienteActual) setClienteOnline(true);
        else setClienteOnline(false);
      });

      socket.on(`cliente-${cliente._id}`, (accion, mensaje) => {
        switch (accion) {
          case EventosSocket.PUSH_MENSAJE:
            setData((prev) => ({
              count: prev.count + 1,
              rows: [...prev.rows, { ...mensaje, nuevoMensaje: true }],
              nuevoMensaje: true,
            }));
            if (esVisible) {
              socket.emit(EventosSocket.MENSAJE_VISTO, {
                clienteId: cliente?._id,
                tipoMensaje,
              });
            }
            break;
          default:
            break;
        }
      });

      socket.emit(EventosSocket.CLIENTES_SALA);

      return () => {
        socket.off(`cliente-${cliente._id}`);
        socket.off(EventosSocket.ENTRAR_SALA);
      };
    }
  }, [socket, cliente, setData, esVisible, tipoMensaje]);

  return (
    <Paper className={styles.root} style={{ height: match ? 0 : height }} ref={ref}>
      <Header
        avatar={cliente.logo}
        nombre={`${cliente.Nombres} ${cliente.Apellidos}`}
        correo={cliente.Email}
        online={clienteOnline}
      />
      <Box flex={1} className={styles.mensajesContainer}>
        <Grid container spacing={2} style={{ height: '100%' }}>
          {primeraCarga ? <Grid item xs={12} className={styles.skeletonRoot}>
            {[...Array(5).keys()].map((key) => <Box key={key} className={styles.skeletonContainer} >
              <Skeleton variant='circle' width={40} height={40} />
              <Box className={styles.skeletonText}>
                <Skeleton width='30%' />
                <Skeleton width='60%' />
              </Box>
            </Box>)}
          </Grid>
            : <Grid item xs={12}>
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    className="List"
                    height={height}
                    width={width}
                    itemCount={data.count}
                    itemSize={80}
                    ref={listRef}
                    onScroll={handleChatScroll}
                    initialScrollOffset={mensajesPorPagina + 1}
                    direction='vertical'
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            </Grid>}
        </Grid>
      </Box>
      <Input cliente={cliente} tipoMensaje={tipoMensaje} />
    </Paper>
  );
};

Chat.propTypes = {
  /** Identificador de un elemento a tomar como referencia para el tamaño en altura */
  idTamanoPadre: propTypes.string,
  /** Objeto que contiene la informacion del cliente */
  cliente: propTypes.object.isRequired,
  /** Indica el porcentaje de altura en funcion del tamaño del padre */
  porcentajeAltura: propTypes.number,
  /** Indica el tipo de mensaje (entrada / salida) */
  tipoMensaje: propTypes.oneOf(['E', 'S']),
};

Chat.defaultProps = {
  porcentajeAltura: 0.85,
  tipoMensaje: 'S',
}

export default Chat;

export {
  Burbuja,
};
