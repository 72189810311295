import { useMemo } from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: 17,
    width: 17,
    borderRadius: '50%',
    border: 'white 2px solid'
  }
}))

const OnlineDot = ({ online }) => {
  const styles = useStyles();
  const colors = useMemo(() => ({
    ONLINE: '#0AC947',
    OFFLINE: '#C7C6C5',
  }), []);

  return <div className={styles.root} style={{ backgroundColor: online ? colors.ONLINE : colors.OFFLINE }}></div>
}

export default OnlineDot;
