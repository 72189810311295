import { useState, useEffect, useCallback, memo } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Default from '../../contenedores/Default';
import PaperContainer from '../../componentes/PaperContainer';
import Typography from '../../componentes/Typography';
import TextField from '../../componentes/TextField';
import Chat, { Burbuja } from '../../componentes/Chat';

import useSocket from '../../hooks/useSocket';
import axios from '../../configuraciones/axios';
import general from '../../configuraciones/general';
import EventosSocket from '../../constantes/eventosSocket';
import endpoints, { CHATS } from '../../configuraciones/endpoints';
import { removerNotificacion } from '../../ducks/user';

const Conversaciones = () => {
  const notificaciones = useSelector((store) => store.user.notificaciones);
  const socket = useSocket(general.NOMBRE_CHAT);
  const dispatch = useDispatch();

  const [clientes, setClientes] = useState([]);
  const [txtBusqueda, setTxtBusqueda] = useState('');
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);

  const seleccionarCliente = useCallback((index, cliente) => {
    setClienteSeleccionado(cliente);
  }, []);

  const consultarDatos = useCallback(async (txtBusqueda, noajax) => {
    const clientes = await axios.get(CHATS, { params: { txtBusqueda, noajax } });
    setClientes(clientes);
  }, []);

  const consultarPorBusqueda = useCallback(async (txtBusqueda) => {
    const clientes = await axios.get(endpoints.buscarChatPorCliente(), {
      params: {
        txtBusqueda,
      },
    });
    setClientes(clientes)
  }, [])

  const buscar = useCallback(({ keyCode }) => {
    if (keyCode === 13) {
      consultarPorBusqueda(txtBusqueda);
    }
  }, [consultarPorBusqueda, txtBusqueda]);

  useEffect(() => {
    consultarDatos();
  }, [consultarDatos]);

  useEffect(() => {
    if (!socket) return;
    socket.on(EventosSocket.RECIBIR_MENSAJE, (clientes) => {
      if (!txtBusqueda) setClientes(clientes);
    });
    return () => {
      socket.off(EventosSocket.RECIBIR_MENSAJE);
    }
  }, [socket, consultarDatos, txtBusqueda]);

  useEffect(() => {
    if (clienteSeleccionado)
      dispatch(removerNotificacion(clienteSeleccionado.id));
  }, [clienteSeleccionado, dispatch]);

  useEffect(() => {
    if (notificaciones.includes(clienteSeleccionado?.id))
      dispatch(removerNotificacion(clienteSeleccionado.id));
  }, [notificaciones, clienteSeleccionado, dispatch]);

  return <Default
    titulo={''}
    placeHolder={''}
  >
    <Grid container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Clientes</Typography>
            </Grid>
            <Grid item xs={12}>
              <PaperContainer idTamanoPadre="root" porcentajeAltura={0.82}>
                {clientes.map((cliente, index) => (
                  <Burbuja
                    key={index}
                    cliente={cliente}
                    onClick={(c) => seleccionarCliente(index, c)}
                  />
                ))}
              </PaperContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end'>
                <Grid item xs={3}>
                  <TextField
                    placeHolder="ID/SUSCRIPTOR"
                    value={txtBusqueda}
                    onChange={({ target: { value } }) => setTxtBusqueda(value)}
                    onKeyDown={buscar}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {clienteSeleccionado && <Chat
                cliente={clienteSeleccionado}
                idTamanoPadre="root"
                porcentajeAltura={0.79}
                tipoMensaje="S"
              />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>
};

export default memo(Conversaciones);
