import queryString from 'query-string';

export const SESIONES = '/sesion';
export const CLIENTES = '/clientes';
export const CHATS = '/chats';
export const PLANES = '/planes';
export const LANDING = '/landing';
export const PAGOS = '/pagos';

export const CLIENTE_URI = '/cliente';

const endpoints = {
  base: {
    url: (uri, id = '') => `${uri}/${id}`,
    busqueda: (uri, params) => `${uri}/busqueda${queryString.stringify(params)}`,
  },
  iniciarSesion: () => `${SESIONES}/admon`,
  chat: (clienteId) => `${CHATS}${CLIENTE_URI}/${clienteId}`,
  clientesChat: () => `${CHATS}`,
  obtenerEstatusSuscripcion: () => `${CLIENTES}/suscripcion-estatus`,
  obtenerPlanes: () => `${LANDING}${PLANES}`,
  obtenerPagos: (clienteId) => `${PAGOS}${CLIENTE_URI}/${clienteId}`,
  buscarChatPorCliente: () => `${CHATS}${CLIENTES}`
};

export default endpoints;
